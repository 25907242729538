import React, { useEffect, useState } from "react";
import { Row, Button, Input, Form, notification, Spin, Col } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import { addProject, getProjects, resetProjectState, editProject } from "./projectActions";
import "../styles/AddProject.css";
import notificationCloseIcon from "../assets/icons/notificationCloseIcon.svg"

const {TextArea} = Input;

const AddProject = (props) => {
    const [projectName, setProjectName] = useState("")
    const [projectLink, setProjectLink] = useState("")
    const [projectDescription, setProjectDescription] = useState("")
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { loading, addProjectError, addedProject, allProjects, editedProject, editProjectError, loadingEditProject } = useSelector((state) => state.project);
    const [isEditProjectScreen, setIsEditProjectScreen] = useState(props.editProject);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

    const handleSaveBtnClick = () => {
        if(!props.editProject){
            const newProject = {
                appName: projectName,
                appUrl: projectLink,
                description: projectDescription
            }
            dispatch(addProject(newProject));
        } else {
            const updatedProject = {
                appName: projectName || props.selectedProjectRecord.appName,
                appUrl: projectLink || props.selectedProjectRecord.appUrl,
                description: projectDescription || props.selectedProjectRecord.description,
                id: props.selectedProjectRecord.id
            }
            dispatch(editProject(updatedProject));
        }
    }

    useEffect(() => {
        if (addProjectError) {
          notification.error({
            description: <span className="buttonText notificationText">{addProjectError}</span>,
            placement: "bottomLeft",
            duration: 5,
            className: "notification",
            closeIcon: <img src={notificationCloseIcon} className="notificationCloseIcon" onClick={() => notification.destroy()} />
          });
        } else if (addedProject) {
            notification.success({
                description: <span className="buttonText notificationText">{"'" + projectName + "'" + ' ' + 'project added successfully'}</span>,
                placement: "bottomLeft",
                duration: 5,
                className: "notification",
                closeIcon: <img src={notificationCloseIcon} className="notificationCloseIcon" onClick={() => notification.destroy()} />
            });
            setProjectName("");
            setProjectLink("");
            setProjectDescription("");
            dispatch(getProjects());
            props.handleModalClose()
        }
      }, [addProjectError, addedProject]);
    
      useEffect(() => {
        if (editProjectError) {
          notification.error({
            description: <span className="buttonText notificationText">{editProjectError}</span>,
            placement: "bottomLeft",
            duration: 5,
            className: "notification",
            closeIcon: <img src={notificationCloseIcon} className="notificationCloseIcon" onClick={() => notification.destroy()} />
          });
        } else if (editedProject) {
            notification.success({
                description: <span className="buttonText notificationText">{"'" + props.selectedProjectRecord.appName + "'" + ' ' + 'project updated successfully'}</span>,
                placement: "bottomLeft",
                duration: 5,
                className: "notification",
                closeIcon: <img src={notificationCloseIcon} className="notificationCloseIcon" onClick={() => notification.destroy()} />
            });
            setProjectName("");
            setProjectLink("");
            setProjectDescription("");
            dispatch(getProjects());
            props.handleModalClose()
        }
      },[editProjectError, editedProject])

    const handleCancelBtnClick = () => {
        props.handleModalClose()
    }

    useEffect(() => {
        return () => {
          dispatch(resetProjectState());
        };
      }, []);

    const handleSaveButtonDisable = () => {
        let saveButtonDisabled;
        if(!props.editProject){
            saveButtonDisabled = !(projectName && projectLink && projectDescription) || (projectName && projectName.length < 3)
            || (allProjects && allProjects.data && projectName && allProjects.data.filter(project => project.appName.toLowerCase() === projectName.toLowerCase()).length > 0)
            || (projectLink && !projectLink.match(/^(ftp|http|https):\/\/[^ "]+$/) || (projectDescription && projectDescription.trim() === ""))
        } else {
            saveButtonDisabled = !(projectDescription) || (projectDescription === props.selectedProjectRecord.description)
        }
        setSaveButtonDisabled(saveButtonDisabled)
    }
    
    useEffect(() => {
        handleSaveButtonDisable()
    }, [projectName, projectLink, projectDescription])


    return(
        <>
        <Spin spinning={loading || loadingEditProject} tip="Loading...">
            <Form form={form} onFinish={handleSaveBtnClick} layout="vertical" >
                <Col className="projectDetailRow">
                    <span className="buttonText labelText">Project Name<span className="requiredMark">*</span></span>
                    <Form.Item
                            name="projectName"
                            initialValue={props.editProject ? props.selectedProjectRecord.appName : ''}
                            rules={[
                            {
                                required: true,
                                message: "Please enter Project name",
                            },
                            {
                                min: 3,
                                message: "Project name should be of minimum 3 characters",
                            },
                            () => ({
                                validator(_, value) {
                                if(allProjects && allProjects.data && value && allProjects.data.filter(project => project.appName.toLowerCase() === value.toLowerCase()).length > 0) {
                                    return Promise.reject(new Error('Project already exists with same name'));
                                }else return Promise.resolve();}
                            })
                            ]}
                        >
                            <Input type="text" id="projectName" placeholder={!props.editProject ? "Enter project name" : ""}
                            className={props.editProject ? "projectDataText projectDetailField" : "projectDataText"} 
                            disabled={props.editProject}
                            value={props.editProject ? props.selectedProjectRecord.appName : projectName}
                            onChange={(e) => {
                                    setProjectName(e.target.value);
                                    form.setFieldsValue({ projectName: e.target.value });
                                    form.validateFields(['projectName']);
                            }}/><br />
                        </Form.Item>
                </Col>
                <Col className="projectDetailRow">
                    <span className="buttonText labelText">Project Link<span className="requiredMark">*</span></span>
                    <Form.Item
                        name="projectLink"
                        initialValue={props.editProject ? props.selectedProjectRecord.appUrl : ''}
                        rules={[
                        {
                            required: true,
                            message: "Please enter Project link",
                        },
                        {
                            type: "url",
                            message: "Please enter Project link as valid URL",
                        }
                        ]}
                    >
                        <Input type="url" id="projectLink" placeholder={!props.editProject ? "Enter project link" : ""}
                        value={props.editProject ? props.selectedProjectRecord.appUrl : projectLink}
                        className={props.editProject ? "projectDataText projectDetailField" : "projectDataText"} 
                        disabled={props.editProject}
                            onChange={(e) => {
                                setProjectLink(e.target.value);
                                form.setFieldsValue({ projectLink: e.target.value });
                                form.validateFields(['projectLink']);
                          }
                            }/><br />
                    </Form.Item>
                </Col>
                <Col className="projectDescriptionRow">
                <span className="buttonText labelText">Project Description</span>
                    <Form.Item
                        name="projectDescription"
                        initialValue={props.editProject ? props.selectedProjectRecord.description : ''}
                        rules={[
                        {
                            required: true,
                            message: "Please enter Project description",
                        },
                        () => ({
                            validator(_, value) {
                            if(value && value.trim() === "") {
                                return Promise.reject(new Error('Please enter Project description'));
                            }else return Promise.resolve();}
                        })
                        ]}
                    >
                        <TextArea id="projectDescription" 
                        className="projectDataText"
                         placeholder={!props.editProject ? "Enter project description" : ""}
                            value={isEditProjectScreen || (props.editProject && !projectDescription) ? props.selectedProjectRecord.description : projectDescription}
                            onChange={(e) => {
                                setIsEditProjectScreen(false);
                                setProjectDescription(e.target.value);
                                form.setFieldsValue({ projectDescription: e.target.value });
                                form.validateFields(['projectDescription']);
                          }}/><br />
                    </Form.Item>
                </Col>
                <Col className="addProjectModalFooter">
                    <Row className="addProjectModalFooterRow">
                        <Button id="btn-cancelAddProject" className="modalButton" onClick={handleCancelBtnClick}>
                            <span className="buttonText modalButtonText">Cancel</span></Button>
                        <Button id="btn-addProject" className={saveButtonDisabled ? "modalButton modalButtonDisabled" : "modalButton modalButtonEnabled"} htmlType="submit"
                                disabled={saveButtonDisabled}>
                                    <span className={saveButtonDisabled ? "buttonText modalButtonText" : "buttonText modalButtonTextEnabled"} >Save</span>
                        </Button>
                    </Row>
                </Col>
            </Form> 
            </Spin>
        </>
    )
}

export default AddProject;