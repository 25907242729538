import React, {useState, useEffect} from 'react';
import {Row, Col, Button, Input, Card, Pagination, Avatar, Spin, Modal, Radio, Form, Popover} from 'antd';
import '../styles/Dashboard.css';
import '../styles/Pagination.css';
import sortIcon from '../assets/icons/sort.svg';
import searchIcon from '../assets/icons/search.svg';
import projectInfoIcon from '../assets/icons/projectInfo.svg';
import addProjectIcon from '../assets/icons/addProject.svg';
import selectedRadioBtn from '../assets/icons/selectedRadioBtn.svg';
import radioBtn from "../assets/icons/radioBtn.svg";
import closeIcon from "../assets/icons/closeIcon.svg";
import errorIcon from "../assets/icons/errorIcon.svg";
import { useDispatch, useSelector } from 'react-redux';
import { getProjects } from "./projectActions";
import {itemsPerDashboardPage} from "./constants";
import AddProject from "./AddProject";

const { Search } = Input;

const Dashboard = ({userInitials}) => {
    const [projectData, setProjectData] = useState([]);
    const [allProjectsData, setAllProjectsData] = useState([]);
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
    const dispatch = useDispatch();
    const { loadingProjects, projects, allProjects } = useSelector((state) => state.project);
    const [currentPage, setCurrentPage] = useState(1);
    const [isAddProjectModalVisible, setIsAddProjectModalVisible] = useState(false);
    const [searchedProjects, setSearchedProjects] = useState(null);
    const [lastUpdateSort, setLastUpdateSort] = useState(false);
    const [alphabeticalSort, setAlphabeticalSort] = useState(false); 
    const [searchMode, setSearchMode] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [sortedProjects, setSortedProjects] = useState([]);
    const [isSortModalVisible, setIsSortModalVisible] = useState(false);
    const [sortOption, setSortOption] = useState(null);
    const [clickedProjectInfo, setClickedProjectInfo] = useState(null);
    const [projectsOnEachScreen, setProjectsOnEachScreen] = useState(0);

    useEffect(() => {
        dispatch(getProjects());
    }, [dispatch]);

    useEffect(() => {
        if(allProjects && allProjects.data){
            setAllProjectsData(allProjects.data); 
        }
        if(projects && projects.data){
            setProjectData(projects.data);
            setLastEvaluatedKey(projects.lastEvaluatedKey);
            setProjectsOnEachScreen(projects.data.length);
        }
    },[loadingProjects, projects, allProjects]);

    useEffect(() => {
        if(!(alphabeticalSort || lastUpdateSort || searchMode)){
            dispatch(getProjects(itemsPerDashboardPage, lastEvaluatedKey, currentPage));
        }
        window.scrollTo(0, 0);
    },[dispatch, currentPage, lastEvaluatedKey]);

    useEffect(() => {
        if(!searchMode && (alphabeticalSort || lastUpdateSort)){
            const startIndex = (currentPage - 1) * itemsPerDashboardPage;
            const endIndex = startIndex + itemsPerDashboardPage;
            let data = allProjectsData;
            if(alphabeticalSort){
                data = [...data].sort((a, b) => a.appName.localeCompare(b.appName));
            } else if(lastUpdateSort){
                data = [...data].sort((a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated));
            }
            setSortedProjects(data);
            setProjectData([...data].slice(startIndex, endIndex));
            setProjectsOnEachScreen(data.slice(startIndex, endIndex).length);
        }
    },[searchMode])

    useEffect(() => {
        if (currentPage === 1 && searchedProjects && searchedProjects.length <= itemsPerDashboardPage) {
            setProjectData(searchedProjects);
            setProjectsOnEachScreen(searchedProjects.length);
        }
    }, [currentPage, searchedProjects]);

    useEffect(() => {
        // Add class to parent .ant-pagination-item element when it contains an ellipsis span
        const ellipsisItems = document.querySelectorAll('.ant-pagination-item-ellipsis');
        ellipsisItems.forEach(item => {
            const parent = item.closest('.ant-pagination-item');
            if (parent) {
                parent.classList.add('non-clickable');
            }
        });
    });

    const itemRender = (current, type, originalElement) => {
        const totalPages = Math.ceil((searchMode && searchedProjects ? searchedProjects.length : allProjects && allProjects.totalCount) / itemsPerDashboardPage);
        if (type === 'page') {
            if (totalPages <= 5) {
                return originalElement;
            }
    
            if (current === 1 || current === totalPages) {
                return originalElement;
            }
    
            if (currentPage <= 3) {
                if (current <= 4) {
                    return originalElement;
                }
                if (current === 5) {
                    return <span key={current} className="ant-pagination-item-ellipsis">...</span>;
                }
            } else if (currentPage >= totalPages - 2) {
                if (current >= totalPages - 3) {
                    return originalElement;
                }
                if (current === totalPages - 4) {
                    return <span key={current} className="ant-pagination-item-ellipsis">...</span>;
                }
            } else {
                if (current === currentPage - 1 || current === currentPage || current === currentPage + 1) {
                    return originalElement;
                }
                if (current === currentPage - 2 || current === currentPage + 2) {
                    return <span key={current} className="ant-pagination-item-ellipsis">...</span>;
                }
            }
            return null;
        }
        if (type === 'jump-prev' || type === 'jump-next') {
            return null; // Hide the jump-prev and jump-next buttons
        }
        return originalElement;
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        const startIndex = (page - 1) * itemsPerDashboardPage;
        const endIndex = startIndex + itemsPerDashboardPage;
        let data = ""
        if(alphabeticalSort || lastUpdateSort || searchMode){
            data = searchMode && searchedProjects.length ? searchedProjects : sortedProjects;
            setProjectData([...data].slice(startIndex, endIndex));
        } else {
            data = allProjectsData;
        }
        setProjectsOnEachScreen(data.slice(startIndex, endIndex).length);
    };

    const handleTileClick = (project) => {
        window.open(project.appUrl, "_blank");
    }

    const handleProjectInfo = (project) => {
        setClickedProjectInfo(project);
    }

    const handleOk = () => {
        setIsAddProjectModalVisible(false);
      };
    
    const handleCancel = () => {
        setIsAddProjectModalVisible(false);
    };

    const handleSearchClick = (e) => {
        let searchedValue = e.target.value;
        if(searchedValue.length >= 3) {
            let searchedProjects = allProjectsData.filter(project => project.appName.toLowerCase().includes(searchedValue.toLowerCase()));
            if(searchedProjects && searchedProjects.length > 0) {
                searchedProjects = alphabeticalSort ? [...searchedProjects].sort((a, b) => a.appName.localeCompare(b.appName)) :
                    lastUpdateSort ? [...searchedProjects].sort((a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated)) : searchedProjects;
                if(searchedProjects.length <= itemsPerDashboardPage){
                    setCurrentPage(1);
                } else{
                    const totalPages = Math.ceil(searchedProjects.length / itemsPerDashboardPage);
                    const validCurrentPage = currentPage > totalPages ? totalPages : currentPage;
                    const startIndex = (validCurrentPage - 1) * itemsPerDashboardPage;
                    const endIndex = startIndex + itemsPerDashboardPage;
                    setProjectData([...searchedProjects].slice(startIndex, endIndex));
                    setProjectsOnEachScreen(searchedProjects.slice(startIndex, endIndex).length);
                }
                setErrorMessage("")
            } else{
                setErrorMessage("Nothing to show");
            }
            setSearchedProjects(searchedProjects)
            setSearchMode(true);
        } else{
            setSearchMode(false);
            setErrorMessage("");
            setSearchedProjects(null)
            const startIndex = (currentPage - 1) * itemsPerDashboardPage;
            const endIndex = startIndex + itemsPerDashboardPage;
            setProjectData([...allProjectsData].slice(startIndex, endIndex));
            setProjectsOnEachScreen(allProjectsData.slice(startIndex, endIndex).length);
        }
        setSearchValue(searchedValue)
    }

    const handleAssendingAlphabetSort = () => {
        setLastUpdateSort(false);
        setAlphabeticalSort(!alphabeticalSort);
        if(!alphabeticalSort){
            let listOfProjects = searchMode && searchedProjects.length ? [...searchedProjects] : [...allProjectsData];
            let sortedProjects = [...listOfProjects].sort((a, b) => a.appName.localeCompare(b.appName));
            const startIndex = (currentPage - 1) * itemsPerDashboardPage;
            const endIndex = startIndex + itemsPerDashboardPage;
            setSortedProjects(sortedProjects);
            setProjectData([...sortedProjects].slice(startIndex, endIndex));
            setProjectsOnEachScreen(sortedProjects.slice(startIndex, endIndex).length);
        }else {
            setSortedProjects([]);
        }
    }

    const handleLastUpdateSort = () => {
        setAlphabeticalSort(false);
        setLastUpdateSort(!lastUpdateSort);
        if(!lastUpdateSort){
            let listOfProjects = searchMode && searchedProjects.length ? [...searchedProjects] : [...allProjectsData];
            let sortedProjects = [...listOfProjects].sort((a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated));
            const startIndex = (currentPage - 1) * itemsPerDashboardPage;
            const endIndex = startIndex + itemsPerDashboardPage;
            setSortedProjects(sortedProjects);
            setProjectData([...sortedProjects].slice(startIndex, endIndex));
            setProjectsOnEachScreen(sortedProjects.slice(startIndex, endIndex).length);
        }else{
            setSortedProjects([]);
        }
    }

    const handleSortChange = (e) => {
        setSortOption(e.target.value);
        setIsSortModalVisible(false); 
        if(e.target.value === "assendingSort"){
            handleAssendingAlphabetSort();
        } else if(e.target.value === "lastUpdatedSort"){
            handleLastUpdateSort();
        }
    };

    const CustomRadio = ({ value, id, children }) => (
        <Radio value={value} className='sortColumn'>
            <span className='radioButtons' id='radioButtons'>
                {sortOption === value ? (
                <img src={selectedRadioBtn} alt="Selected Radio Button" />
                ) : (
                    <img src={radioBtn} alt="Radio Button" />
                )}
            </span>
            <span className='sortText' id={id}>{children}</span>
        </Radio>
    );

    const sortContent = (
        <Form>
          <Form.Item>
            <Radio.Group onChange={handleSortChange} value={sortOption}>
              <CustomRadio value="assendingSort" id="assendingSort">
                A to Z
              </CustomRadio>
              <CustomRadio value="descendingSort" id="descendingSort">
                Z to A
              </CustomRadio>
              <CustomRadio value="lastUpdatedSort" id="lastUpdatedSort">
                Last Updated
              </CustomRadio>
              <CustomRadio value="lastUsedSort" id="lastUsedSort">
                Last Used
              </CustomRadio>
            </Radio.Group>
          </Form.Item>
        </Form>
      );

    const handleInfoModalCancel = () => {
        setClickedProjectInfo(null);
    }

    return(
        <>
            <Spin spinning={loadingProjects} tip="Loading...">
            <Row className='rowHeading'>
                <Col className='columnHeading1'>
                <Row className='dashboardHeading' id="dashboardHeading" >Welcome to AICC Labs </Row>
                <Row className='heading' id="heading">Dashboard</Row>
                <Row className='description' id="description">Here you can find all AICC projects</Row>
                </Col>
                <Col className='columnHeading2'>
                    <Row className='userInitialRow'>
                        <Avatar id="loggedUser" className='userInitials'>
                        <span className='userInitialsText'>{userInitials}</span>
                      </Avatar>
                    </Row>
                    <Button className = "addProjectButton" id="dashboardAddProjectButton" onClick={() => setIsAddProjectModalVisible(true)}>
                        <img src={addProjectIcon} alt="Add Project Icon" className="addProjectIcon" />
                        <span className='buttonText addProjectBtnText'>Add Project</span>
                    </Button>
                </Col>
            </Row>
            <hr className='dashboardDivider' />
            <Row className='rowContent'>
                <Row className='sortSearchRow'>
                    <Popover
                    className='sortPopover'
                    content={sortContent}
                    title={null}
                    trigger="click"
                    // visible={isSortModalVisible}
                    // onVisibleChange={setIsSortModalVisible}
                    placement="bottom"
                    id="sortPopover"
                    >
                    <Button className='sortButton'  id="sortButton"
                    // onClick={() => setIsSortModalVisible(true)}
                    >
                        <img src={sortIcon} alt="Sort Icon" className="sortIcon" />
                        <span className='buttonText sortButtonText'>Sort by</span>
                    </Button>
                    </Popover>
                    <Input className="searchInput" id="searchInput" placeholder="Search" onChange={handleSearchClick}
                    prefix={1===1 ? <img src={searchIcon} alt="Search Icon" className="searchIcon" /> : null} />
                </Row>
                <Row className='projectContent'>
                {errorMessage && <div className='errorMessageDiv'>
                    <div className='errorMessage'>
                    <span className='errorIconSpan'>
                        <img src={errorIcon} alt="error Icon" className='errorIcon' />
                    </span>
                    <span className='errorMessageSpan'>
                        <span className='buttonText lineheight24'>{errorMessage}</span>
                        <span className='projectTitle'>Try adjusting your filters or keywords.</span>
                    </span>
                    </div>
                </div>}
                {projectData && projectData.map((project, index) => {
                    return(
                        <Card 
                            className='projectCard'
                            key={index}
                            hoverable
                            id={index + 1}
                            onClick={() => handleTileClick(project)}
                            cover={
                            <img
                                src={project.imageURL}
                                alt={project.appName}
                                className="projectImage"
                                width="1792" 
                                height="1024"
                            />} 
                        > 
                        <Card.Meta className='projectDetails' 
                            title={
                                <div className='projectDescription'>
                                    <span className='projectTitle'>{project.appName}</span>
                                    <span className='projectLastUpdate'>{`Last updated: ${formatDate(project.lastUpdated)}`}</span>
                                </div>
                            } 
                            description={
                                <div>
                                    <Button className='projectIconButton' id={project.appName + " info"} onClick={e => {e.stopPropagation(); handleProjectInfo(project)}}>
                                        <img src={projectInfoIcon} alt="project Info Icon" className='projectInfoIcon' />
                                    </Button>
                                </div>
                            }
                        />
                        </Card>
                    )
                    
                })}
                </Row>
                <div className={!errorMessage ? (projectsOnEachScreen < 6 ? 'paginationRowFixed' : 'paginationRow') : 'paginationInError'}>
                    <Pagination
                            align="end"
                            current={currentPage}
                            pageSize={itemsPerDashboardPage}
                            total={searchMode && searchedProjects ? searchedProjects.length : allProjects && allProjects.totalCount}
                            onChange={handlePageChange}
                            showSizeChanger={false} // number of projects per page can be changed using this as true, 
                            //by default true for total items > 50
                            showTitle={false}// to hide default tooltip
                            showTotal={(total, range) => (
                                <span>{`${range[0]}-${range[1]} of ${total} items`}</span>
                            )}
                            itemRender={itemRender} //to show customazed pagination
                    />
                </div>
            </Row>
            </Spin>
            {isAddProjectModalVisible && (
                <Modal
                    title={<>
                            {"Add Project"}
                            <Button className = "closeIconButton" id="closeModal" onClick={handleCancel}>
                                <img src={closeIcon} alt="Close Button" className='closeIcon' />
                            </Button>
                            </>}
                    open={isAddProjectModalVisible}
                    footer={null}
                    className='addProjectModal'
                    >
                    <AddProject handleModalClose={handleCancel}/>
                </Modal>
            )}
            {clickedProjectInfo && (
                <Modal
                title={<>
                    {clickedProjectInfo.appName}
                    <Button className = "closeIconButton" id="closeModal" onClick={handleInfoModalCancel}>
                        <img src={closeIcon} alt="Close Button" className='closeIcon' />
                    </Button>
                    </>}
                open={clickedProjectInfo}
                onCancel={handleInfoModalCancel}
                cancelText="Close"
                id={clickedProjectInfo.appName + " modal"}
                className='projectInfoModal'
            >
                <span className="modalBodyText">{clickedProjectInfo.description}</span>
            </Modal>
            )}
        </>
    )
}

export default Dashboard;