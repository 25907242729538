import { ADD_PROJECT_REQUEST, ADD_PROJECT_SUCCESS, ADD_PROJECT_FAILURE, RESET_PROJECT_STATE,
  GET_PROJECT_REQUEST, GET_PROJECT_SUCCESS, GET_PROJECT_FAILURE, GET_ALL_PROJECT_SUCCESS, 
  EDIT_PROJECT_REQUEST, EDIT_PROJECT_SUCCESS, EDIT_PROJECT_FAILURE,
  DELETE_PROJECT_REQUEST, DELETE_PROJECT_SUCCESS, DELETE_PROJECT_FAILURE
 } from './constants';

const initialState = {
  loading: false,
  addedProject: null,
  addProjectError: null,
  loadingProjects: false,
  allProjects: null,
  projects: null,
  getProjectError: null,
  loadingEditProject: false,
  editedProject: null,
  editProjectError: null,
  deleteProjectError: null,
  loadingDeleteProject: false,
  deletedProject: null,
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        addedProject: action.payload,
        addProjectError: null,
      };
    case ADD_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        addedProject: null,
        addProjectError: action.payload,
      };
    case GET_PROJECT_REQUEST:
        return {
          ...state,
          loadingProjects: true,
        };
      case GET_ALL_PROJECT_SUCCESS:
        return {
          ...state,
          loadingProjects: false,
          allProjects: action.payload,
          projects: null,
          getProjectError: null
        };
      case GET_PROJECT_SUCCESS:
        return {
          ...state,
          loadingProjects: false,
          projects: action.payload,
          getProjectError: null,
        };
      case GET_PROJECT_FAILURE:
        return {
          ...state,
          loadingProjects: false,
          allProjects: null,
          projects: null,
          getProjectError: action.payload,
        };
    case RESET_PROJECT_STATE:
      return {
        ...state,
        loading: false,
        addedProject: null,
        addProjectError: null,
        loadingEditProject: false,
        editedProject: null,
        editProjectError: null,
        deleteProjectError: null,
        loadingDeleteProject: false,
        deletedProject: null,
      };
    case EDIT_PROJECT_REQUEST:
      return {
        ...state,
        loadingEditProject: true,
      };
    case EDIT_PROJECT_SUCCESS:
      return {
        ...state,
        loadingEditProject: false,
        editedProject: action.payload,
        editProjectError: null,
      };
    case EDIT_PROJECT_FAILURE:
      return {
        ...state,
        loadingEditProject: false,
        editedProject: null,
        editProjectError: action.payload,
      };
    case DELETE_PROJECT_REQUEST:
      return {
        ...state,
        loadingDeleteProject: true,
      };
    case  DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        loadingDeleteProject: false,
        deletedProject: action.payload,
        deleteProjectError: null,
      };
    case DELETE_PROJECT_FAILURE:
      return {
        ...state,
        loadingDeleteProject: false,
        deletedProject: null,
        deleteProjectError: action.payload,
      };
    default:
      return state;
  }
};

export default projectReducer;